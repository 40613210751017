import { Link } from "gatsby";
import React from "react";
import Arrow from "assets/rightarrow.svg";
import Avator1 from "assets/avator1.svg";
import Avator2 from "assets/avator2.svg";
import Avator3 from "assets/avator3.svg";
import Avator4 from "assets/avator4.svg";
import Avator5 from "assets/avator5.svg";
import Avator6 from "assets/avator6.svg";
// import Avator7 from "assets/avator7.svg";
import Avator8 from "assets/avator8.svg";
import Avator9 from "assets/avator9.svg";
import Avator10 from "assets/avator10.svg";

const EqualOpportunity = () => {
  const Imagedata = [
    { img: Avator1, name: "Arianne R.", city: "Philippines" },
    { img: Avator2, name: "Ortal L.", city: "Israel" },
    { img: Avator3, name: "Denis C.", city: "Brazil" },
    { img: Avator4, name: "Brenda D.", city: "Philippines" },
    { img: Avator5, name: "Haidelyn D.", city: "Philippines" },
    { img: Avator6, name: "Audrey M.", city: "Philippines" },
    // { img: Avator7, name: "Maya R.", city: "Israel" },
    { img: Avator8, name: "Allysa M.", city: "Philippines" },
    { img: Avator9, name: "Keith M.", city: "Philippines" },
    { img: Avator10, name: "Phoebe R.", city: "Philippines" },
  ];
  return (
    <div className="  px-4 pt-5 sm:p-6 md:py-24 pb-8">
      <div className="max-w-6xl mx-auto ">
        <div className="grid lg:grid-cols-2 gap-32 my-12">
          <div className="flex flex-col items-center  lg:justify-center">
            <div className="w-max-h-max">
              <h2 className=" text-2xl  font-primary font-semibold leading-8] ">
                We’re a Proud Equal Opportunity Employer
              </h2>

              <p className=" py-[40px] text-base  md:text-lg font-normal font-opensans  leading-6">
                Tomedes is steadfast in its commitment to equal opportunity,
                ensuring a discrimination-free hiring process that values
                diversity and inclusion at every level.
              </p>
            </div>
          </div>
          <div>
            <div className="grid grid-cols-3 gap-4 lg:gap-6 ">
              {Imagedata.map((item, index) => (
                <div className="relative group flex flex-col items-center justify-center">
                  <img
                    src={item.img}
                    alt=""
                    className="h-[170px] md:w-[170px] w-[130px] object-cover text-center filter grayscale text-white contrast-70 hover:filter-none hover:opacity-100 transition-all duration-300 rounded-lg"
                  />
                  <h3 className="absolute bottom-6 text-base font-semibold text-white font-opensans opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    {item.name}
                  </h3>
                  <p className="absolute bottom-2 text-sm font-normal text-white font-opensans  opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    {item.city}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EqualOpportunity;
