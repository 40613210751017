import React from "react";
import Tomedesstep1 from "assets/carrier/tomedesstep1.webp";
import Tomedesstep2 from "assets/carrier/tomedesstep2.webp";
import Tomedesstep3 from "assets/carrier/tomedesstep3.webp";
const ApplicationProcess = () => {
  return (
    <div className="bg-[#FFEFE2] px-4">
      <div className="sm:px-6 py-[120px]   max-w-6xl mx-auto">
        <h1 className="lg:px-28 font-semibold font-primary text-[40px] pb-[120px] text-[#00173A] leading-[60px] text-center">
          Our Quick & Easy Application Process
        </h1>
        <div className="">
          <div className="mt-12 grid lg:grid-cols-2 gap-16 lg:gap-32">
            <div className="flex flex-col items-center justify-center">
              <div>
                <p className="text-[#649DF2] font-semibold text-xl leading-5">
                  Step 1
                </p>
                <h1 className="text-[#00173A] text-3xl font-primary font-semibold leading-[52px] ">
                  Explore Opportunities
                </h1>

                <p className=" mt-8 text-lg font-normal font-opensans leading-7">
                  Browse our job openings to find the right match for your
                  skills and interests.
                </p>
              </div>
            </div>
            <div>
              <div className="flex items-center justify-center">
                <img src={Tomedesstep1} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="py-12 mt-8">
          <div className="mt-12 grid lg:grid-cols-2 gap-16 lg:gap-32">
            <div className="order-2 lg:order-1">
              <div className="flex items-center justify-center">
                <img src={Tomedesstep2} alt="" />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center order-1 lg:order-2">
              <div>
                <p className="text-[#649DF2] font-semibold text-xl leading-5">
                  Step 2
                </p>
                <h1 className="text-[#00173A] text-3xl font-primary font-semibold leading-[52px] ">
                  Send us your Resume
                </h1>

                <p className=" mt-8 text-lg font-normal font-opensans leading-7">
                  Find the perfect role & send your resume to
                  vendors@tomedes.com for translators or hr@tomedes.com for
                  other positions.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8">
          <div className="mt-12 grid lg:grid-cols-2  gap-16 lg:gap-32">
            <div className="flex flex-col items-center justify-center">
              <div>
                <p className="text-[#649DF2] font-semibold text-xl leading-5">
                  Step 3
                </p>
                <h1 className="text-[#00173A] text-3xl font-primary font-semibold leading-[52px] ">
                  Application Review & interview
                </h1>

                <p className=" mt-8 text-lg font-normal font-opensans leading-7">
                  If you’re a stand out candidate, we’ll reach out for an
                  interview and get to know each other better.
                </p>
              </div>
            </div>
            <div>
              <div className="flex items-center justify-center">
                <img src={Tomedesstep3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ApplicationProcess;
