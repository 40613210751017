import React from "react";
import { perkBenefitsData } from "./config";
import Arrow from "assets/ci_arrow-right-lg (1).svg";

const PerkBenefets = () => {
  return (
    <div>
      <div className=" px-4 pt-5 sm:p-6 md:py-24 pb-8">
        <div className="max-w-6xl mx-auto  text-[#00173A]">
          <h1 className="font-primary text-3xl md:text-4xl font-semibold leading-[60px] lg:pr-60 text-center lg:text-left ">
            Excellent Employee Perks and Benefits
          </h1>
          <p className=" mt-8 text-lg font-normal  leading-6 text-center md:text-left">
            Embrace the freedom of flexible work arrangements from the comfort
            of your home.
          </p>
          {/* <button className=" mt-12 flex text-[#649df2] text-semibold text-lg leading-6 font-opensans ">
            Explore Our Career Benefits
            <img src={Arrow} alt="" className="mt-[2px] ml-2" />
          </button> */}
          <div className="mt-[80px] grid lg:grid-cols-4 gap-10 ">
            {perkBenefitsData.map((items, i) => (
              <div className="flex flex-col w-60  gap-6">
                <div className="h-[36px]">
                  <img src={items.imgUrl} alt="" />
                </div>

                <h2 className="text-lg font-medium font-primary leading-7  h-12 ">
                  {items.heading}
                </h2>
                <p className="text-base  font-normal leading-7 font-opensans">
                  {items.subheading}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PerkBenefets;
