import Priority from "assets/priority.svg";
export const perkBenefitsData = [
  {
    imgUrl: Priority,
    heading: "Flexible Work Arrangements",
    subheading:
      "Adjust your schedule for a harmonious work-life balance with flexible hours tailored to your lifestyle.",
  },
  {
    imgUrl: Priority,
    heading: "Work From Home",
    subheading:
      "Excel in a familiar environment while enjoying  the comfort and convenience of remote work.",
  },
  {
    imgUrl: Priority,
    heading: "Professional Development Opportunities",
    subheading:
      "Thrive with continuous learning opportunities and resources for career growth.",
  },
  {
    imgUrl: Priority,
    heading: "Team-Building Activities",
    subheading:
      "Connect and collaborate at engaging team events that foster community and teamwork.",
  },
];
