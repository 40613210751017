import { Link } from "gatsby";
import React from "react";
import Arrow from "assets/rightarrow.svg";
import Tomedesteam from "assets/carrier/tomedesteam.webp";

const TomedesWork = () => {
  return (
    <div className="  px-4 pt-5 sm:p-6 md:py-24 pb-8">
      <div className="max-w-6xl mx-auto p-4 ">
        <h1 className="text-[#00173A] text-4xl  font-primary font-semibold leading-[60px] text-center ">
          Why work with Tomedes?
        </h1>
        <div className="grid lg:grid-cols-2 gap-24 my-12">
          <div className="flex flex-col items-center  lg:justify-center">
            <div className="">
              <h2 className=" text-[28px] lg:w-max font-primary font-semibold leading-8] ">
                People-Centered Culture and Values
              </h2>

              <p className=" mt-8 text-base  md:text-lg font-normal font-opensans  leading-6">
                We value excellence, innovation, & unity in every interaction.
                Above all, people are the heartbeat of our culture. You matter
                here.
              </p>
              <div className="pt-[40px]">
                <Link to="/about-tomedes ">
                  <button className=" mt-12 flex text-[#2a7cf8]  text-semibold text-lg leading-6 font-opensans">
                    Discover Our Story
                    <img
                      src={Arrow}
                      alt=""
                      className="mt-[3px]"
                      style={{
                        filter:
                          "brightness(0) saturate(100%) invert(65%) sepia(98%) saturate(6300%) hue-rotate(201deg) brightness(91%) contrast(105%)",
                      }}
                    />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div className="flex items-center justify-center">
              <img src={Tomedesteam} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TomedesWork;
