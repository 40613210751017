import React from "react";
import Layout from "components/layout";
// import Careers from "components/Careers";
import SEO from "components/seo";
import Carriercomp from "components/CarrierComponent";
function Index() {
  // const pageData = props.pageContext.node.data;
  // console.log(pageData, "pagedata");
  const title = "Tomedes Jobs - Work at Tomedes | Tomedes Careers";
  const description =
    "We are looking for talented Project Managers, Account Managers, Freelance Translators, Interpreters, Editors and Content writers to join our team. Explore career opportunities within TOMEDES TRANSLATION.";
  const keywords = "content";
  const slug = "/careers";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug={slug}
      />
      {/* <Careers /> */}
      <Carriercomp />
    </Layout>
  );
}

export default Index;
