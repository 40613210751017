import React, { useEffect, useState } from "react";

import Arrow from "assets/rightarrow.svg";
import { Link } from "gatsby";

const JobCategory = () => {
  const [isDesktop, setIsDesktop] = useState(
    typeof window !== "undefined" && window.innerWidth >= 1024
  );

  const JobCategoryData = [
    {
      heading:
        "Translation and" + (isDesktop ? "<br />" : " ") + "Interpreting",
      subheading:
        "Facilitate global connections through translation and interpreting roles.",
      linktext: "See all translation jobs",
    },
    {
      heading: "Project" + (isDesktop ? "<br />" : " ") + "Management",
      subheading:
        "Guide linguistic projects to success and collaborate with a diverse, skilled team.",
      linktext: "Coming Soon",
    },
    {
      heading: "Language" + (isDesktop ? "<br />" : " ") + "Services",
      subheading:
        "Connect communities in roles like transcribing, subtitling, transcreation, and more.",
      linktext: "Coming Soon",
    },
    {
      heading: "Customer" + (isDesktop ? "<br />" : " ") + "Support",
      subheading:
        "Be the helpful voice that ensures customer satisfaction every step of the way.",
      linktext: "Coming Soon",
    },
    {
      heading: "Digital" + (isDesktop ? "<br />" : " ") + "Marketing",
      subheading:
        "Join our collaborative digital marketing team and help shape the narrative of Tomedes ",
      linktext: "Coming Soon",
    },
    {
      heading: "Web" + (isDesktop ? "<br />" : " ") + "Development",
      subheading:
        "Develop innovative digital infrastructure, the backbone of our language services platform.",
      linktext: "Coming Soon",
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(typeof window !== "undefined" && window.innerWidth >= 1024);
    };

    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  return (
    <div className="px-4">
      <div className="pt-5 sm:px-6 pb-[120px]  max-w-7xl mx-auto">
        <div>
          <h1 className="text-[#00173A] text-[40px] font-primary font-semibold text-center leading-[60px]">
            Explore Jobs by Category
          </h1>
        </div>
        <div className="grid lg:grid-cols-3 gap-x-[22px] gap-y-[3rem] mt-[80px]">
          {JobCategoryData.map((item, i) => (
            <div className="bg-[#E7F0FF]  rounded-lg p-10" key={i}>
              <h3
                dangerouslySetInnerHTML={{ __html: item.heading }}
                className="font-primary text-[26px] text-[#00173A] font-medium leading-9 "
              ></h3>
              <p className="mt-[26px] text-[#00173A] text-lg font-opensans leading-7 font-normal h-24  ">
                {item.subheading}
              </p>
              <div className="flex gap-2 mt-10 ">
                {i == 0 ? (
                  <Link to="/careers/translation-jobs">
                    <button className="flex font-opensans text-[#2A7CF8] font-semibold text-lg leading-7">
                      {item.linktext}
                      <img
                        src={Arrow}
                        alt=""
                        className="mt-[3px] pl-2"
                        style={{
                          filter:
                            "brightness(0) saturate(100%) invert(65%) sepia(98%) saturate(6300%) hue-rotate(201deg) brightness(91%) contrast(105%)",
                        }}
                      />
                    </button>
                  </Link>
                ) : (
                  <div className="flex font-opensans px-2 p-1 bg-[#234BE21A] rounded-sm text-[#2A7CF8] font-semibold text-lg leading-7 ">
                    {item.linktext}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default JobCategory;
