import React from "react";
import Hero from "assets/carrier/heroimg.webp";
import QualityGurantee from "components/YearGuarantee/quality-gurantee";
import { Link } from "gatsby";
import JobCategory from "./jobCategory";
import ApplicationProcess from "./applicationProcess";
import TomedesWork from "./tomedesWork";
import PerkBenefets from "./perkBenefits";
import EqualOpportunity from "./equalOppurtinity";
import Employsaying from "./employsaying";

const Carriercomp = () => {
  //   const pageData = props.pageContext.node.data;

  const backgroundImageStyle = {
    backgroundImage: `url(${Hero})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const buttonContent = (
    <button className="bg-[#FF7B16] px-[28px] py-[20px] font-opensans font-semibold rounded-[25rem] w-full md:w-max">
      Register as a Translator
    </button>
  );

  return (
    <div>
      <section
        className="px-4  py-12 lg:h-[600px] flex items-center"
        style={backgroundImageStyle}
      >
        <div className="pt-5 sm:px-6 py-5  max-w-6xl mx-auto">
          <div className="flex flex-col items-center justify-center gap-8 text-[#ffffff]">
            <h1 className=" font-bold text-4xl md:text-[50px] leading-[62px] pb-8 text-white font-primary text-center">
              Tomedes Careers: Join Our Team
            </h1>

            <p
              style={{ lineHeight: "2rem" }}
              className="text-center  font-opensans text-base md:text-lg font-normal pb-8
            lg:px-24"
            >
              As pioneers in the language industry, we always welcome top
              talent. Come shape the future of global communication with
              Tomedes.
            </p>
            <div className="w-full md:w-max mt-6  md:mt-0">
              <Link to="/user/provider/createaccount">{buttonContent}</Link>
            </div>
          </div>
        </div>
      </section>
      <div className="w-11/12 max-w-7xl mx-auto flex flex-col">
        <div className="hidden lg:block w-full px-4 2xl:px-0 py-7 ">
          <div className="flex max-w-7xl mx-auto gap-3 items-center text-base text-[#494949] font-opensans font-semibold">
            <Link to="/">
              <img
                src="https://tomedes.gumlet.io/S3UploadedFiles/1666089711home.svg"
                alt="home"
              />
            </Link>

            <img
              src="https://tomedes.gumlet.io/S3UploadedFiles/1666089692greater-than.svg"
              alt="navigate"
            />

            <span>Careers</span>
          </div>
        </div>
      </div>
      <JobCategory />
      <ApplicationProcess />
      <TomedesWork />
      <PerkBenefets />
      <EqualOpportunity />
      <Employsaying />
    </div>
  );
};
export default Carriercomp;
