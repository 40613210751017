// import React from "react";
// import Tomedesteam from "assets/carrier/carvalho.svg";
// import Nexticon from "assets/nexticon.svg";
// import Pre from "assets/pre.svg";
// import "swiper/css";
// import "swiper/css/grid";
// import Click from "assets/carrier/click.svg";
// import Danis from "assets/carrier/danis.svg";

// import { Navigation, Pagination } from "swiper/modules";
// import { Swiper, SwiperSlide } from "swiper/react";

// const Employsaying = () => {
//   return (
//     <div className="bg-[#00173A] py-44">
//       <div className="  px-4  sm:p-6 ">
//         <div className="max-w-6xl mx-auto p-4 ">
//           <h1 className="text-4xl font-opensans text-semibold text-center text-[#FFFFFF] leading-[60PX]">
//             What Our Employees are Saying
//           </h1>

//           <div className=" pt-[80px]">
//             <div className=" relative ">
//               <Swiper
//                 slidesPerView={1}
//                 spaceBetween={30}
//                 pagination={{
//                   clickable: true,
//                   el: ".swiper-pagination",
//                 }}
//                 navigation={{
//                   nextEl: ".swiper-button-next",
//                   prevEl: ".swiper-button-prev",
//                 }}
//                 style={{
//                   height: "auto",
//                   paddingTop: "0px",
//                   paddingBottom: "80px",
//                 }}
//                 className="mySwiper "
//                 modules={[Navigation]}
//               >
//                 <SwiperSlide
//                   style={{
//                     background: "transparent",
//                     height: "100%",
//                   }}
//                 >
//                   <div className="lg:flex  ">
//                     <img
//                       src={Tomedesteam}
//                       alt=""
//                       className=" lg:block hidden h-[460px]"
//                     />
//                     <img
//                       src={Danis}
//                       alt=""
//                       className="lg:hidden block w-full "
//                     />
//                     <div className="px-12 pt-12 w-full h-[570px] md:h-[460px]  pb-24 lg:pb-12 bg-[#0E2A56] ">
//                       <img src={Click} alt="" className=" relative top-6" />
//                       <div className="absolute ">
//                         <p className="pl-8 text-lg font-opensans text-start pr-6 md:pr-0 text-normal leading-7 text-[#ffffff]">
//                           Working at Tomedes has been such an incredible journey
//                           with even better people to share it with. I love
//                           working in such a dynamic work environment that pushes
//                           you to learn and evolve every day.
//                         </p>
//                         <h3 className="pl-8 text-xl mt-8 font-primary text-start leading-6 font-medium text-[#ffffff]">
//                           Denis Carvalho
//                         </h3>
//                         <p className="pl-8 text-xl mt-4 font-primary text-start leading-6 font-medium text-[#ffffff]">
//                           November 15, 2023
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                 </SwiperSlide>

//                 {/* <div className="absolute h-16 flex gap-2 items-center justify-center z-10 right-20 sm:right-[270px] lg:right-6 bottom-24 ">
//                   <img
//                     style={{ height: "60px", width: "60px" }}
//                     src={Nexticon}
//                     alt="Next"
//                     className=""
//                   />

//                   <img
//                     style={{ height: "60px", width: "60px" }}
//                     src={Pre}
//                     alt="Previous"
//                     className=""
//                   />
//                 </div> */}
//                 {/* <div className="absolute h-16 flex gap-2 items-center justify-center  border-2 border-white bg-white z-11 right-12 bottom-24 ">
//                   <div className="swiper-pagination border-2 border-white lg:hidden  block"></div>
//                 </div> */}
//               </Swiper>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default Employsaying;
import React from "react";
import Tomedesteam from "assets/carrier/carvalho.webp";

import "swiper/css";
import "swiper/css/grid";
import Click from "assets/carrier/click.svg";
import Danis from "assets/carrier/danis.svg";

const Employsaying = () => {
  return (
    <div className="bg-[#00173A] py-44">
      <div className="  px-4  sm:p-6 ">
        <div className="max-w-6xl mx-auto p-4 ">
          <h1 className="text-4xl font-opensans text-semibold text-center text-[#FFFFFF] leading-[60PX]">
            What Our Employees are Saying
          </h1>

          <div className=" pt-[80px]">
            <div className=" relative ">
              <div className="lg:flex  ">
                <img
                  src={Tomedesteam}
                  alt=""
                  className=" lg:block hidden h-[460px]"
                />
                <img src={Danis} alt="" className="lg:hidden block w-full " />
                <div className="px-12 pt-12 w-full h-[570px] md:h-[460px]  pb-24 lg:pb-12 bg-[#0E2A56] ">
                  <img src={Click} alt="" className=" relative top-6" />
                  <div className="absolute ">
                    <p className="pl-8 text-lg font-opensans text-start pr-6 md:pr-16 text-normal  leading-7 text-[#ffffff]">
                      Working at Tomedes has been such an incredible journey
                      with even better people to share it with. I love working
                      in such a dynamic work environment that pushes you to
                      learn and evolve every day.
                    </p>
                    <h3 className="pl-8 text-xl mt-8 font-primary text-start leading-6 font-medium text-[#ffffff]">
                      Denis Carvalho
                    </h3>
                    <p className="pl-8 text-xl mt-4 font-primary text-start leading-6 font-medium text-[#ffffff]">
                      November 15, 2023
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Employsaying;
